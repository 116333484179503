import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage} from '@ionic/storage'
import { MenuController } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  user: any;
  constructor(
    public storage: Storage,
    public router: Router,
    public menuCtrl:MenuController,
    public serviceForAllService: ServiceForAllService,

  ) {
    this.menuCtrl.enable(false);

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {

      if (!this.serviceForAllService.isPlatformAndroidIos) {
        const myObj = JSON.parse(localStorage.getItem("user:patienthands"));
        if (myObj) {
          this.router.navigate(['/tabs/home']);
          resolve(false);
        } else {
          resolve(true);
          this.menuCtrl.enable(false);

        }

        
    }

    if (this.serviceForAllService.isPlatformAndroidIos) {

      this.storage.get('user:patienthands').then((data: any) => {
        if (data) {
          this.router.navigate(['/tabs/home']);
          resolve(false);
        } else {
          resolve(true);
          this.menuCtrl.enable(false);

        }
      })
    }
    });

  }

}