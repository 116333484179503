// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDTj_6inVVSrQWlemV4VoTArAUGediR6os",
    authDomain: "patienthandsapp.firebaseapp.com",
    projectId: "patienthandsapp",
    storageBucket: "patienthandsapp.appspot.com",
    messagingSenderId: "666262112611",
    appId: "1:666262112611:web:5663845d664fd1f6e2eb56"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
