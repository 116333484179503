import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { InAppBrowser, InAppBrowserOptions, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';
import { Stripe } from '@ionic-native/stripe/ngx';
import { ReactiveFormsModule } from '@angular/forms';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { ActionSheet, ActionSheetOptions } from '@ionic-native/action-sheet/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ComponentsModule } from './components/components.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { Device } from '@ionic-native/device/ngx';
import { RemovejobPageModule } from './removejob/removejob.module';
import { TagEmployeePageModule } from './tag-employee/tag-employee.module';
import { FilterPageModule } from './filter/filter.module';
import { SpecialtyModalPageModule } from './modal/specialty-modal/specialty-modal.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PaymentSubscriptionPageModule } from './payment-subscription/payment-subscription.module';
@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            animated: false,
            mode: 'ios'
        }),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        ReactiveFormsModule,
        RemovejobPageModule,
        TagEmployeePageModule,
        FilterPageModule,
        SpecialtyModalPageModule,
        PaymentSubscriptionPageModule
    ],
    providers: [
        StatusBar,
        Keyboard,
        SplashScreen,
        Camera,
        File,
        FileTransfer,
        // NativeStorage,
        ActionSheet,
        Geolocation,
        // OneSignal,
        InAppBrowser,
        Stripe,
        Device,
        InAppPurchase,
        NativeGeocoder,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
