import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';

@Component({
  selector: 'app-tag-employee',
  templateUrl: './tag-employee.page.html',
  styleUrls: ['./tag-employee.page.scss'],
})
export class TagEmployeePage implements OnInit {
  tag_users: any = [];
  checked_tag_users: any = [];
  loginUser: any = [];
  page: number = 1;
  employe_list: any = [];
  searchName: any;
  isLoader: boolean = false;
  isReady: boolean = false;
  count: number = 0;

  constructor(
    public modalController: ModalController,
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
  ) {
    if (this.serviceForAllService.isPlatformAndroidIos) {
    this.storage.get('user:patienthands').then((user: any = []) => {
      if (user != null) {
        this.loginUser = user;
        this.getEmploye();
        console.log('tag_users :>> ', this.tag_users);
      }
    });
  }

    if (!this.serviceForAllService.isPlatformAndroidIos) {

      let user = JSON.parse(localStorage.getItem("user:patienthands"));
      if (user != null) {
        this.loginUser = user;
        this.getEmploye();
      }
  }
  }

  ngOnInit() {

  }

  async closeModal(action) {
    await this.modalController.dismiss(
      { users: this.tag_users }
    );
  }

  getEmploye() {
    console.log('this.serchName :>> ', this.searchName);
    this.isLoader = true;
    let api_data = {
      token: this.loginUser.token,
      page: this.page,
      searchName: this.searchName
    }
    this.serviceForAllService.sendData('get_my_employees', api_data).subscribe((res) => {

      this.isLoader = false;
      this.isReady = true;
      let result: any = [];
      result = res;
      this.count = result.employee_count;
      // this.employe_list = result.employe_list

      result.employe_list.map(element => {
        if (this.tag_users.includes(element.user_id)) {
          element.checked = true;
        } else {
          element.checked = false;
        }
        this.employe_list.push(element);
      })
      // this.searchName = '';

    }, (err) => {
      console.log('err.error :>> ', err.error);
      this.isLoader = false;
      this.isReady = true;
    })
  }

  loadMore(event) {

    let api_data = {
      token: this.loginUser.token,
      page: ++this.page,
      searchName: this.searchName
    }
    this.serviceForAllService.sendData('get_my_employees', api_data).subscribe((res) => {

      let result: any = [];
      result = res;

      if (result.employee_count > 0) {
        // this.employe_list = [...this.employe_list, ...result.employe_list];
        result.employe_list.map(element => {
          if (this.tag_users.includes(element.user_id)) {
            element.checked = true;
          } else {
            element.checked = false;
          }
          this.employe_list.push(element);
        })
      }

      this.count = result.employee_count;
      event.target.complete();
    }, (err) => {
      console.log('err.error :>> ', err.error);
      event.target.complete();
    });
  }

  searchUser(event) {
    let feedName = event.detail.value;
    this.searchName = feedName;
    this.getEmploye();
  }

  chooseUser(item) {
    console.log('item :>> ', item);
    if (this.tag_users.indexOf(item.user_id) == -1) {
      this.tag_users.push(item.user_id);
    } else {
      let temp = this.tag_users.indexOf(item.user_id);
      this.tag_users.splice(temp, 1);
    }
    console.log('this.tag_users :>> ', this.tag_users);
  }
}
