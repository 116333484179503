import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from 'src/app/service-for-all.service';

@Component({
  selector: 'app-specialty-modal',
  templateUrl: './specialty-modal.page.html',
  styleUrls: ['./specialty-modal.page.scss'],
})
export class SpecialtyModalPage implements OnInit {

  loginUserInfo:any = [];
  is_loader:boolean = false;
  page:number = 1;
  specialties:any = [];
  count:any;
  error_msg:string;
  selected_specialties:any = [];
  selected_specialties_ids:any = [];

  constructor(
    public modalController: ModalController,
    public storage : Storage,
    public router : Router,
    public service : ServiceForAllService
  ) { 
    this.storage.get("user:patienthands").then((user)=>{
      if(user != null){
        this.loginUserInfo = user;
        this.getSpecialty();
      }else{
        this.router.navigate(['/login']);
      }
    })
  }

  ngOnInit() {
  }

  dismiss(action) {
    this.modalController.dismiss({
      'action': action,
      'selected_specialties' : this.selected_specialties,
      'selected_specialties_ids' : this.selected_specialties_ids,
    });
  }

  getSpecialty(){
    this.is_loader = true;
    let api_data :any = {
      token : this.loginUserInfo.token,
      page : this.page,
    }
    this.service.sendData("get_specialties",api_data).subscribe((res)=>{
      if(res['status'] == 'ok'){
        this.specialties = res['specialties'];
        let i=0;
        this.specialties.forEach((s) => {
          if(this.selected_specialties_ids.indexOf(s.id) > -1){
            this.specialties[i].isChecked = true;
          }else{
            this.specialties[i].isChecked = false;
          }
          i++;
        });

        this.count = res['count'];
        if(res['count'] == 0){
          this.error_msg = "No specialties";
        }
      }else{
        this.service.presentToast("Somethin went wrong. Please try again");
      }
      this.is_loader = false;
    },(err)=>{
      this.is_loader = false;
      this.service.presentAlert(err.error.message);
      console.log('err :>> ', err);
    });
  }

  getSpecialtyMore(event){
    let api_data :any = {
      token : this.loginUserInfo.token,
      page : ++this.page,
    }
    this.service.sendData("get_specialties",api_data).subscribe((res)=>{
      if(res['status'] == 'ok'){
        if(res['count'] > 0){
          this.specialties =  [...this.specialties, ...res['specialties']];
          let i=0;
          this.specialties.forEach((s) => {
            if(this.selected_specialties_ids.indexOf(s.id) > -1){
              this.specialties[i].isChecked = true;
            }else{
              this.specialties[i].isChecked = false;
            }
            i++;
          });

          this.count = res['count'];
          this.error_msg = "No more specialties";
        }
        this.count = res['count'];
      }else{
        this.service.presentToast("Somethin went wrong. Please try again");
      }
      event.target.complete();
    },(err)=>{
      event.target.complete();
      this.service.presentAlert(err.error.message);
      console.log('err :>> ', err);
    });
  }

  selectSpeciality(item){
    
    const ind = this.selected_specialties.findIndex(ele => ele.id == item.id);
    if(ind == -1){
      this.selected_specialties.push(item);
      this.selected_specialties_ids.push(item.id);
    }else{
      this.selected_specialties.splice(ind,1);
      this.selected_specialties_ids.splice(ind,1);
    }
  }
}
