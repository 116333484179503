import { ChangeDetectorRef, Component } from '@angular/core';
import { Platform, NavController, AlertController, MenuController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { Events } from './events';
import { Router } from '@angular/router';
import { ServiceForAllService } from './service-for-all.service';
import * as firebase from 'firebase';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Device } from '@ionic-native/device/ngx';

declare let window: any;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    user: any = [];
    deviceData: any = [];
    role: any;
    public appPages = [];
    public selectedIndex = 0;
    deviceToken: any = [];
    fromandroid: boolean;
    membershipEnable: any;
    menu_back_button: boolean = false;

    constructor(
        public device: Device,
        // public oneSignal: OneSignal,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private navController: NavController,
        public alertController: AlertController,
        public storage: Storage,
        public events: Events,
        public menu: MenuController,
        private router: Router,
        public serviceForAllService: ServiceForAllService,
        public cd: ChangeDetectorRef
    ) {


        this.appinitialization();


        if (!this.serviceForAllService.isPlatformAndroidIos) {
            this.menu_back_button = false;
        } else {
            this.menu_back_button = true;
        }

        const firebaseConfig = {
            apiKey: "AIzaSyDTj_6inVVSrQWlemV4VoTArAUGediR6os",
            authDomain: "patienthandsapp.firebaseapp.com",
            projectId: "patienthandsapp",
            storageBucket: "patienthandsapp.appspot.com",
            messagingSenderId: "666262112611",
            appId: "1:666262112611:web:5663845d664fd1f6e2eb56"
        };
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);

        if (this.serviceForAllService.isPlatformAndroidIos) {
            this.storage.get('user:patienthands').then((user: any = []) => {
                if (user) {
                    this.user = user;
                    console.log('user :>> ', user);
                    this.initializeMenu(this.user);
                }
            });
        }
        if (!this.serviceForAllService.isPlatformAndroidIos) {
            let val = JSON.parse(localStorage.getItem("user:patienthands"));
            if (val) {
                this.user = val;
                this.initializeMenu(this.user);
            }

        }

        // this.memberShipModal();
    }

    async doLogout() {
        const alert = await this.alertController.create({
            header: 'One Eleven Healthcare',
            message: 'Are you sure ?',
            buttons: [{
                text: 'Yes',
                handler: () => {
                    // if (this.platform.is('cordova')) {
                    //   this.removeToken();
                    // } else {

                    if (this.serviceForAllService.isPlatformAndroidIos) {
                        this.storage.remove('user:patienthands').then((data) => {
                            this.storage.remove('selectedCat');
                            this.serviceForAllService.firebaseSignOut();
                            this.menu.close();
                            this.navController.navigateRoot('/login');
                        })
                    }

                    if (!this.serviceForAllService.isPlatformAndroidIos) {
                        localStorage.removeItem('user:patienthands')
                        this.serviceForAllService.firebaseSignOut();
                        this.menu.close();
                        this.navController.navigateRoot('/login');

                    }

                    // }
                }
            },
            {
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {

                }
            }
            ]
        });
        await alert.present();
    }
    statusBarColorChange() {
        this.statusBar.overlaysWebView(false);
        this.statusBar.show();
        this.statusBar.backgroundColorByHexString('#FFF');
        this.statusBar.styleDefault();
    }
    appinitialization() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.statusBarColorChange();
            this.splashScreen.hide();
            if (this.platform.is('cordova') && this.serviceForAllService.isPlatformAndroidIos) {
                this.OneSignalInit();
            }
            if (this.serviceForAllService.isPlatformAndroidIos) {
                this.events.subscribe('user:login', (check) => {
                    this.initializeMenu(check.user);
                });
            }

            if (!this.serviceForAllService.isPlatformAndroidIos) {
                this.events.subscribe('user:login', (check) => {
                    this.initializeMenu(check.user);
                });
            }

        }, (err) => {
            console.log('err :>> ', err);
        });
    }




    OneSignalInit(): void {

        // window["plugins"].OneSignal.setAppId("ef9b2e00-c9fb-45aa-a814-68016121804f");
        // window["plugins"].OneSignal.setNotificationOpenedHandler(function (jsonData) {
        //     console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
        // });


        // window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
        //     console.log("User accepted notifications: " + accepted);
        // });
        // window["plugins"].OneSignal.setNotificationWillShowInForegroundHandler(function (notificationReceivedEvent) {
        //     notificationReceivedEvent.complete(notificationReceivedEvent.notification);
        // });

        (window as any).plugins.OneSignal.setAppId("ef9b2e00-c9fb-45aa-a814-68016121804f");
        (window as any).plugins.OneSignal.setNotificationOpenedHandler(function (jsonData) {
            console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
        });


        (window as any).plugins.OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
            console.log("User accepted notifications: " + accepted);
        });
        (window as any).plugins.OneSignal.setNotificationWillShowInForegroundHandler(function (notificationReceivedEvent) {
            notificationReceivedEvent.complete(notificationReceivedEvent.notification);
        });
    }

    initializeMenu(user) {
        console.log("initializeMenu: ", { user });
        if (user.role == "publisher") {
            this.appPages = [
                {
                    title: 'Home',
                    url: '/tabs/home',
                    icon: 'home'
                },
                {
                    title: 'Notification',
                    url: '/notification',
                    icon: 'notifications'
                },
                {
                    title: 'Subscription',
                    url: '/subscription',
                    icon: 'settings'
                },
                {
                    title: 'Messages',
                    url: '/tabs/messages',
                    icon: 'messages'
                },
                {
                    title: 'Settings',
                    url: '/settings',
                    icon: 'settings'
                },
                {
                    title: 'Payout to Employee',
                    url: '/payout-to-employee',
                    icon: 'settings'
                },
                {
                    title: 'About Us',
                    url: '/aboutus',
                    icon: 'document-text'
                },
                {
                    title: 'Contact us',
                    url: '/contact',
                    icon: 'call'
                },
                {
                    title: 'Terms and Conditions ',
                    url: '/terms',
                    icon: 'card'
                },
                {
                    title: 'Privacy Policy ',
                    url: '/privacy',
                    icon: 'card'
                },


            ];
        } else {
            this.appPages = [
                {
                    title: 'Notifications',
                    url: '/notification',
                    icon: 'notifications'
                },
                {
                    title: 'Work History',
                    url: '/work-history',
                    icon: 'settings'
                },
                {
                    title: 'References',
                    url: '/references',
                    icon: 'card'
                },
                {
                    title: 'Profile',
                    url: '/tabs/profile',
                    icon: 'profile'
                },
                {
                    title: 'Messages',
                    url: '/tabs/messages',
                    icon: 'messages'
                },
                {
                    title: 'Settings',
                    url: '/settings',
                    icon: 'settings'
                },
                {
                    title: 'About Us',
                    url: '/aboutus',
                    icon: 'document-text'
                },
                {
                    title: 'Contact us',
                    url: '/contact',
                    icon: 'call'
                },
                {
                    title: 'Terms and Conditions ',
                    url: '/terms',
                    icon: 'card'
                },
                {
                    title: 'Privacy Policy ',
                    url: '/privacy',
                    icon: 'card'
                }

            ];
        }
        if (this.serviceForAllService.isPlatformAndroidIos) {
            this.cd.detectChanges();
        }



    }


    menuToggle() {
        this.menu.toggle();
    }

    // removeToken() {
    //     this.serviceForAllService.showLoader('loading...');
    //     this.oneSignal.getIds().then((data) => {
    //         console.log(data);
    //         this.deviceToken = data.userId;
    //         this.deviceData.push({
    //             uuid: this.device.uuid,
    //             model: this.device.model,
    //             platform: this.device.platform,
    //             version: this.device.version,
    //             deviceToken: this.deviceToken,
    //         });
    //         this.storage.get('user:patienthands').then((val) => {
    //             this.user = val;
    //             this.serviceForAllService.updateToken(this.user.token, this.deviceToken, this.deviceData, 'logout')
    //                 .subscribe((res) => {
    //                     this.serviceForAllService.dismissLoading();
    //                     this.storage.remove('user:patienthands').then((data) => {
    //                         this.storage.remove('selectedCat');
    //                         // this.storage.clear();
    //                         this.menu.close();
    //                         this.navController.navigateRoot('/login');
    //                     });
    //                 }, err => {
    //                     this.serviceForAllService.dismissLoading();
    //                 });

    //         },
    //             (err) => {
    //                 this.serviceForAllService.dismissLoading();
    //                 // this.storage.clear();
    //                 this.menu.close();
    //                 this.navController.navigateRoot('/login');
    //             });
    //     }, (err) => {
    //         this.serviceForAllService.dismissLoading();
    //         this.storage.clear();
    //         this.menu.close();
    //         this.navController.navigateRoot('/login');
    //     });

    // }

    memberShipModal() {
        this.storage.get('user:patienthands').then((val) => {
            if (val) {
                console.log('val :>> ', val);
                if (val.role == 'publisher') {
                    if (this.platform.is('android')) {
                        this.fromandroid = true;
                    }

                    let sendData = {
                        token: val.token,
                        fromandroid: this.fromandroid,
                        action: 'receipt-validation',
                        platform: (this.fromandroid ? 'android' : 'ios')
                    }
                    // this.showLoader();
                    console.log("validate_purchase:sendData", sendData);
                    this.serviceForAllService.sendData('validate_purchase', sendData).subscribe((result) => {
                        let res: any = [];
                        res = result['details'];
                        if (res.membershipEnable == 'yes') {
                            console.log('membershipEnable :>> ', res.membershipEnable);
                            // if (res.membershipEnable == 'no') {
                            //     this.membershipEnable = 'no';
                            //     this.serviceForAllService.presentAlert2("You must have subscription to access the app.", 'subscription');
                            // } else {
                            this.membershipEnable = 'yes';
                            // }
                        } else {
                            this.membershipEnable = 'no';
                            this.serviceForAllService.presentAlert2("You must have subscription to access the app.", 'subscription');
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }

            }
        });
    }

}
//
