import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-removejob',
  templateUrl: './removejob.page.html',
  styleUrls: ['./removejob.page.scss'],
})
export class RemovejobPage implements OnInit {

  constructor(private modalController:ModalController) { }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
//   ngOnInit() {  
//   }
//   CloseModal(){
//     this.modalController.dismiss();
//   }
// }
async presentModal() {
  const modal = await this.modalController.create({
    component: RemovejobPage,
    cssClass: 'removejob-class'
  });
  return await modal.present();
}
  CloseModal(){
    this.modalController.dismiss();
  }
}