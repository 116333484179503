import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';
import { AlertController, LoadingController, NavController, MenuController, ActionSheetController, ToastController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
    providedIn: 'root'
})
export class ServiceForAllService {
    wpUrl = 'https://oneelevenhealthcare.org/';
    fullUrl = 'https://oneelevenhealthcare.org/wp-json/mobileapi/v1/';
    url = 'https://oneelevenhealthcare.org/wp-json/wp/v2/';
    pmproUrl = 'https://oneelevenhealthcare.org/wp-json/pmpro/v1/';

    totalPosts = null;
    pages: any;
    loading: any;
    registerType: string = '';
    fromandroid: boolean;
    isPlatformAndroidIos: boolean = false;
    switchSegment:any = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        public alertCtrl: AlertController,
        public loadingCtrl: LoadingController,
        public actionSheetCtrl: ActionSheetController,
        public navCtrl: NavController,
        public toastController: ToastController,
        public platform: Platform,
        public storage: Storage
    ) {
        // const isapp: any = (document.URL.startsWith('http://app.oneelevenhealthcare.org') || document.URL.startsWith('https://app.oneelevenhealthcare.org') || document.URL.startsWith('http://localhost:8100'));

        const isapp: any = (document.URL.startsWith('http://app.oneelevenhealthcare.org') || document.URL.startsWith('https://app.oneelevenhealthcare.org') || document.URL.startsWith('http://localhost:8100') || document.URL.startsWith('http://localhost:8101'));
        if (isapp) {
            console.log("PWA");
            this.isPlatformAndroidIos = false;
            console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
        } else {
            console.log("Device");
            this.isPlatformAndroidIos = true;
            console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
        }
    }

    update_profile(user_data) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/update_profile', user_data)
    }

    doRegister(user_data) {
        console.log("SERVICES === ", user_data);
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/register', {
            email: user_data.email,
            password: user_data.password,
            username: user_data.email,
            first_name: user_data.first_name,
            last_name: user_data.last_name,
            artist_name: user_data.artist_name,
            dob: user_data.dob,
            gender: user_data.gender,
            user_interest: user_data.user_interest,
            jw_auth_sec: "wivxCNm$<(+WFwivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$#7}1]wivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$TWMUl7OaU*TxS(r*$"
        })
    }

    doLogin(email, password) {
        console.log("SERVICE == " + email);
        return this.http.post(this.wpUrl + 'wp-json/jwt-auth/v1/token', {
            username: email,
            password: password,
            jw_auth_sec: "wivxCNm$<(+WFwivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$#7}1]wivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$TWMUl7OaU*TxS(r*$"
        })
    }

    doReset(user_login) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/retrieve_password', {
            user_login: user_login
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            return of(result as T);
        };
    }

    getUser(token, userID: any = 0,jobID:any = 0) {
        if (userID != 0) {
            return this.http.get(this.wpUrl + 'wp-json/mobileapi/v1/getProfile/?user_id=' + userID+ '&jobID='+jobID).pipe(
                map(user => {
                    return user;
                })
            )
        } else {
            return this.http.get(this.wpUrl + 'wp-json/mobileapi/v1/getProfile/?token=' + token).pipe(
                map(user => {
                    return user;
                })
            )
        }

    }

    async showLoader(msg) {
        this.loading = await this.loadingCtrl.create({ message: msg, cssClass: 'custom-load' });
        return await this.loading.present();
    }
    async dismissLoading() {
        return await this.loading.dismiss();
    }
    async presentToast(msg) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000,
            position: 'bottom'
        });
        toast.present();
    }


    getData(endPoint) {
        return this.http.get(this.fullUrl + endPoint).pipe(
            map(data => {
                return data;
            })
        )
    }

    sendData(endPoint, data) {
        return this.http.post(this.fullUrl + endPoint, data).pipe(
            map(data => {
                return data;
            })
        )
    }

    sendDataPmpro(endPoint,data){
        return this.http.post(this.pmproUrl + endPoint,data).pipe(
            map(data => {
                return data;
            })
        )
    }

    get_page_content(token, page_name) {
        return this.http.get(this.wpUrl + 'wp-json/mobileapi/v1/get_page_content/?token=' + token + '&page_name=' + page_name).pipe(
            map(user => {
                return user;
            })
        )
    }
    async presentAlert(msg) {
        const alert = await this.alertCtrl.create({
            cssClass: 'no-user-found',
            header: '',
            subHeader: msg,
            message: '<img src="../assets/submit.png">',
            buttons: ['OK'],
        });

        await alert.present();
    }
    async presentAlert2(msg, route: any = 'no') {
        const alert = await this.alertCtrl.create({
            header: 'Alert',
            message: msg,
            backdropDismiss : false,
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                        if (route == 'no') {
                            this.navCtrl.back();
                        } else {
                            if(route !=''){
                                this.navCtrl.navigateBack(route);
                            }
                        }

                    }
                }
            ]
        });

        await alert.present();
    }

    saveEventData(token, value) {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/save_event_data', {
            value: value,
            token: token,
            timezone: timezone
        })
    }
    upload_image(data) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/upload_image', data).pipe(
            map(data => {
                return data;
            })
        )
    }
    getPageContent(page: number = 1, password: any = '') {
        return this.http.get(
            this.wpUrl +
            'wp-json/wp/v2/pages/' + page + '?password=' + password)
            .pipe(
                map(content => {
                    return content;
                })
            )
    }
    getNews(page = 1, userToken: any = ''): Observable<any[]> {

        let options = {
            observe: "response" as 'body',
            params: {
                per_page: '100',
                page: '' + page
            }
        };

        return this.http.get<any[]>(this.url + 'posts?_embed&token=' + userToken, options).pipe(
            map(resp => {
                this.pages = resp['headers'].get('x-wp-totalpages');
                this.totalPosts = resp['headers'].get('x-wp-total');

                let data = resp['body'];

                for (let post of data) {
                    post.media_url = post['media_url'];
                }
                return data;
            })
        )
    }
    doProfileUpdate(token, values) {
        return this.http.post(this.fullUrl + 'updateProfile', {
            token: token,
            user_data: values
        })
    }
    getMyjobs(token, role: any = 'location_representation', user_id: any = '', search: any = '', shotBy: any = '') {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/getMyjobs', {
            token: token,
            role: role,
            user_id: user_id,
            shortBy: shotBy,
            search: search
        })
    }
    getFeaturedUser(token, role: any = 'applicant', searchByName: any = '', searchByCat: any = '') {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_featured_users', {
            token: token,
            timezone: timezone,
            role: role,
            searchByName: searchByName,
            searchByCat: searchByCat

        })
    }

    approveJobs(token, values) {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/approveJobs', {
            token: token,
            values: values
        })
    }
    applyJobs(token, jobID) {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/applyJobs', {
            token: token,
            jobID: jobID
        })
    }

    cancelJobs(token, jobID) {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/cancelJobs', {
            token: token,
            jobID: jobID
        })
    }
    report_Author(CuserID, blockID) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/BlockUser', {
            CuserID: CuserID,
            blockID: blockID
        })

    }
    get_block_status(currentID, second) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_block_status', {
            currentID: currentID,
            second: second
        })

    }
    unblockPostAuthor(currentID, second) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/unblockPostAuthor', {
            currentID: currentID,
            second: second
        })

    }
    getBothuser(token, seconduser) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/getBothuser', {
            token: token,
            seconduser: seconduser
        })
    }
    getSecoondUserInfo(token, id) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/getSecoondUserInfo', {
            token: token,
            id: id
        })
    }
    updateToken(userToken, deviceID, deviceData, status) {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/updateDeviceToken", {
            token: userToken,
            deviceID: deviceID,
            deviceData: deviceData,
            status: status,
            timezone: timezone

        })
    }
    sendPushNotification(id, msg, name, senderID) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/sendPushNotification', {
            jw_auth_sec: "3t]HdSq_0]SIlU)7e9I +S_89PK=vehgfvghewr43gr43r43g34rt34gr4h3grh3grhT+Pj<V8%v0M6H|,y+_tf]x5XAw5dYy6VM,;(",
            id: id,
            msg: msg,
            name: name,
            senderID: senderID
        })

    }
    getNoti(token, page_no) {
        return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_my_notifications", {
            token: token,
            page: page_no
        })
    }
    deleteNoti(nid) {
        return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/deleteNoti", {
            nid: nid
        })
    }
    get_search_fileds(token, role) {
        return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/get_search_fileds", {
            token: token,
            role: role
        })
    }
    submitReview(token, rating, comment, seekerID, job_id, editCheck = false) {
        return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/submitReview", {
            token: token,
            rating: rating,
            comment: comment,
            seekerID: seekerID,
            job_id: job_id,
            editCheck: editCheck
        })
    }

    removeCard(token, card, type) {
        return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/DeleteCard", {
            token: token,
            card: card,
            type: type
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }
    async SaveAutoConfiqure(token) {
        console.log(token);
        if (this.platform.is('cordova')) {
            // this.oneSignal.getIds().then((id) => {
            //   this.one_id = id.userId;
            //   this.token = token;
            //   this.saveOneSignID(this.token, this.one_id).subscribe(m => {
            //   });
            // });
        }

    }

    setSetting(setting) {
        // if (this.platform.is('cordova')) {
        // this.navStorage.setItem('setting', setting)
        //   .then(
        //     () => console.log('Stored item!'),
        //     error => console.error('Error storing item', error)
        //   );
        // }
    }

    getStoreSetting() {
        // if (this.platform.is('cordova')) {
        // return this.navStorage.getItem('setting');
        // }
    }


    GetSetting() {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/GetSetting', {
        });
    }
    GetCards(token, jobId = '') {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/StripeGetCards', {
            token: token,
            jobid: jobId
        })
    }
    CreateStripeUser(token, stripeToken, type: number = 0) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/CreateStripeUser', {
            token: token,
            stripeToken: stripeToken,
            type: type
        });
    }

    addCardtoBank(token, stripeToken) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/add_debit_card', {
            token: token,
            type: 'card',
            stripeToken: stripeToken
        });
    }

    GetStripeAuthToken(token, details) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/CreateStripeCaptureBooking', {
            token: token,
            Currency: details.Currency,
            Card: details.Card,
            Amount: details.Amount,
            notes: details.notes,
            to_user_id: details.to_user_id,
            myservices: details.myservices,
            invtotalItem: details.invtotalItem,
            invtotalamount: details.invtotalamount,
            select_day: details.select_day,
            select_time: details.select_time,
            select_date: details.select_date

        }).pipe(
            map(data => {
                return data;
            })
        )
    }
    updateCard(token, card, card_new, type) {
        return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/updateCard", {
            token: token,
            card: card,
            name: card_new.name,
            expMonth: card_new.expMonth,
            expYear: card_new.expYear,
            type: type,
            default: card_new.default
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    getTransaction(token, page_no) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/getMyPaymentList', {
            token: token,
            page: page_no
        });
    }
    capturePayment(token, charge_id) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/capturePayment', {
            token: token,
            charge_id: charge_id
        });
    }
    save_stripe_account_id(token, stripe_account_id) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/save_stripe_account_id', {
            token: token,
            stripe_account_id: stripe_account_id
        });
    }
    get_stripe_account_id(token) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/get_stripe_account_id', {
            token: token
        });
    }
    getAvailablePlans(token) {
        return this.http.post(this.wpUrl + "wp-json/mobileapi/v1/getAvailablePlans", {
            token: token,
        })
    }

    createSubscription(token, transcationid, receipt, plan, fromandroid) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/create_user_subscription', {
            token: token,
            transcationid: transcationid,
            receipt: receipt,
            fromandroid: fromandroid,
            plan: plan
        })
    }

    verifyRecipt(token, fromandroid) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/validate_purchase', {
            token: token,
            fromandroid: fromandroid
        })
    }

    updateTranscationId(token, transcationid) {
        return this.http.post(this.wpUrl + 'wp-json/mobileapi/v1/update_subscription_transcation', {
            token: token,
            transcationid: transcationid
        })
    }
    veriFyInservice() {
        this.storage.get('user:patienthands').then((val) => {
            if (val) {
                if (this.platform.is('android')) {
                    this.fromandroid = true;
                }
                this.verifyRecipt(val.token, this.fromandroid).subscribe((result: any = []) => {
                    console.log('this.res: ', result);

                    if (result.membershipEnable) {
                        if (result.membershipEnable == 'no') {
                            return 'no';
                        } else {
                            return 'yes';
                        }
                    } else {
                        return 'no';
                    }
                }, (err) => {
                    console.log(err);
                })
            }
        });
    }

    // Added by AP
    getJobs(page = 1, userToken: any = '', post_title: any = '',uid: any = '', job_type, shortBy:any = '',postPer: any = 10): Observable<any[]> {
        console.log('shortBy :>> ', shortBy);
        let options = {
            observe: "response" as 'body',
            params: {
                per_page: postPer,
                page: '' + page
            }
        };
        return this.http.get<any[]>(this.url + 'job?_embed&token=' + userToken + "&search=" + post_title + "&job_type="+job_type + "&uid="+uid + "&short_by="+shortBy ,options).pipe(
            map(resp => {
                this.pages = resp['headers'].get('x-wp-totalpages');
                this.totalPosts = resp['headers'].get('x-wp-total');

                let data = resp['body'];

                for (let post of data) {
                    post.media_url = post['media_url'];
                }
                return data;
            })
        )
    }
    // End 

    firebaseAuthToken() {
        let email = 'mainuser@knoxweb.com';
        let password = 'sR123456';
        firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {

            console.log('firebase login = ', { user });

        }).catch((err: any = []) => {
            console.log('err = ', { err });
            if (err.code == 'auth/user-not-found') {
                firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
                    console.log('firebase account created = ', { user });
                    this.firebaseAuthToken();
                });
            }

        });
    }

    firebaseSignOut() {
        firebase.auth().signOut();
    }
    custom_backward() {
        this.navCtrl.back()
    }

}
