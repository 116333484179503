import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {  Storage} from '@ionic/storage'
import { ServiceForAllService } from '../service-for-all.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public storage: Storage,
    public router : Router,
    public serviceForAllService:ServiceForAllService
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {

      if (!this.serviceForAllService.isPlatformAndroidIos) {
        const myObj = JSON.parse(localStorage.getItem("user:patienthands"));
        if (myObj) {
          resolve(true);
        } else {
          this.router.navigateByUrl("/login");
          resolve(false);

        }

        
    }

    if (this.serviceForAllService.isPlatformAndroidIos) {

      this.storage.get('user:patienthands').then((val)=>{
        if(val){
          resolve(true);
        }
        else {
          this.router.navigateByUrl("/login");
          resolve(false);
        }
      
    });
  }
  })

}
}

