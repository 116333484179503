import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { Storage } from '@ionic/storage';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-payment-subscription',
  templateUrl: './payment-subscription.page.html',
  styleUrls: ['./payment-subscription.page.scss'],
})
export class PaymentSubscriptionPage implements OnInit {

  plan: any;
  is_cardready: boolean = false;
  cards: any = [];
  loginUser: any = [];

  constructor(
    public modal: ModalController,
    public serviceForAllService: ServiceForAllService,
    public storage: Storage,
    public router: Router,
    public alertController: AlertController,
  ) {
    this.storage.get('user:patienthands').then((user) => {
      if (user) {
        this.loginUser = user;
        this.GetCards();
      }

      setTimeout(() => {
        console.log("plan :", this.plan);
      }, 2000);
    });
  }

  ngOnInit(
  ) {
  }

  modalDismis() {
    this.modal.dismiss();
  }

  GetCards() {
    this.is_cardready = true;
    this.serviceForAllService.GetCards(this.loginUser.token).subscribe((res: any) => {
      if (res.status == "ok") {
        if (res.cards.data.length > 0) {
          this.cards = res.cards.data;
          console.log("cards :", this.cards);
        }
      } else {
        this.serviceForAllService.presentAlert("Something went wrong.");
      }
      this.is_cardready = false;
    }, (err) => {
      console.log("error : ", err);
      this.serviceForAllService.presentAlert(err.error.errormsg);
      this.is_cardready = false;
    });
  }

  async payToSubscription(item) {

    const alert = await this.alertController.create({
      header: 'Payment',
      message: 'Are you sure?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Okay',
          handler: async () => {
            console.log('Confirm Okay');
            await this.serviceForAllService.showLoader("Please wait...");
            this.serviceForAllService.sendDataPmpro('subscribeuser', { "userid": this.loginUser.user_id, "planid": this.plan.id, "card_id": item.id }).subscribe(async (res: any) => {
              console.log("res :", res);
              await this.serviceForAllService.dismissLoading();
              this.modal.dismiss({active_sub:res.active_membership});
              this.serviceForAllService.presentAlert(res.message);
            }, async (err) => {
              console.log("err :", err);
              await this.serviceForAllService.dismissLoading();
              this.serviceForAllService.presentAlert2(err.error.message);
            });
          }
        }
      ]
    });

    await alert.present();

  }

  addCard() {
    this.modalDismis();
    let navigation: NavigationExtras = {
      queryParams: {
        page_type : 'payment_subscription'
      }
    }
    this.router.navigate(['/billing/forpayment'],navigation);
  }
}
