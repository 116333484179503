import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
declare var google;

@Component({
  selector: 'app-filter',
  templateUrl: './filter.page.html',
  styleUrls: ['./filter.page.scss'],
})
export class FilterPage implements OnInit {
  min_salary:number;
  max_salary: number;
  GoogleAutocomplete: any;
  isFilter: boolean = false;
  location: any = '';
  autocompleteItems: any = [];
  latitude: any = 0;
  longitude: any = 0;
  distance:any = 10;

  constructor(
    public modalController : ModalController,
    public cdref : ChangeDetectorRef,
    public zone: NgZone,
  ) { 
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
  }

  ngOnInit() {
  }

  dismiss(action) {

    if (action == 'ok') {

      this.modalController.dismiss({
        'action': action,
        'min_salary': this.min_salary,
        'max_salary': this.max_salary,
        'isFilter': true,
        'latitude': this.latitude,
        'longitude' : this.longitude,
        'location' : this.location,
        'distance' : this.distance,
      });

    } else if (action == 'clear') {

      this.min_salary = 0;
      this.max_salary = 0;
      this.isFilter = false;
      this.latitude = 0;
      this.longitude = 0;
      this.location = '';
      this.distance = 10;

      this.modalController.dismiss({
        'action': action,
        'min_salary': this.min_salary,
        'max_salary': this.max_salary,
        'isFilter': this.isFilter,
        'latitude': this.latitude,
        'longitude' : this.longitude,
        'location' : this.location,
        'distance' : this.distance,
      });

    } else {

      this.modalController.dismiss({
        'action': action,
      });
    }
  }

  updateSearchResults(event:any) {
    
    if (event.target.value == '') {
      this.autocompleteItems = [];
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions({ input: this.location },
      (predictions, status) => {
        this.autocompleteItems = [];
        this.zone.run(() => {
          if (predictions) {
            predictions.forEach((prediction) => {
              this.autocompleteItems.push(prediction);
            });
          }
        });
      });
      console.log('autocompleteItems :>> ', this.autocompleteItems);
  }

  clear(){
    this.autocompleteItems = [];
  }

  selectSearchResult(item) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': item.description }, (results) => {
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
      this.location = item.description;
      this.autocompleteItems = [];
      this.cdref.detectChanges();
      console.log('location :>> ', this.location);
      console.log('latitude :>> ', this.latitude);
      console.log('longitude :>> ', this.longitude);
    });
  }

}
