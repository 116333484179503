import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { LoginGuard } from './guard/login.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    canActivate: [LoginGuard]

  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule),
    canActivate: [LoginGuard]

  },
  {
    path: 'forgot',
    loadChildren: () => import('./forgot/forgot.module').then( m => m.ForgotPageModule),
    canActivate: [LoginGuard]

  },
  {
    path: 'signup-as',
    loadChildren: () => import('./signup-as/signup-as.module').then( m => m.SignupAsPageModule),
    canActivate: [LoginGuard]

  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),

  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then( m => m.MessagesPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule),
    

  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule),
    

  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },

  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then( m => m.NewsPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'date-single',
    loadChildren: () => import('./date-single/date-single.module').then( m => m.DateSinglePageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'seeker',
    loadChildren: () => import('./seeker/seeker.module').then( m => m.SeekerPageModule)
  },
  {
    path: 'create-new-job',
    loadChildren: () => import('./create-new-job/create-new-job.module').then( m => m.CreateNewJobPageModule)
  },

  {
    path: 'set-hours',
    loadChildren: () => import('./set-hours/set-hours.module').then( m => m.SetHoursPageModule)
  },
  {
    path: 'single-news',
    loadChildren: () => import('./single-news/single-news.module').then( m => m.SingleNewsPageModule)
  },
  {
    path: 'rate-seeker',
    loadChildren: () => import('./rate-seeker/rate-seeker.module').then( m => m.RateSeekerPageModule)
  },
  {
    path: 'profile-info-step1',
    loadChildren: () => import('./profile-information/profile-info-step1/profile-info-step1.module').then( m => m.ProfileInfoStep1PageModule)
  },
  {
    path: 'profile-info-step2',
    loadChildren: () => import('./profile-information/profile-info-step2/profile-info-step2.module').then( m => m.ProfileInfoStep2PageModule)
  },
  {
    path: 'profile-info-step3',
    loadChildren: () => import('./profile-information/profile-info-step3/profile-info-step3.module').then( m => m.ProfileInfoStep3PageModule)
  },
  {
    path: 'profile-info-step4',
    loadChildren: () => import('./profile-information/profile-info-step4/profile-info-step4.module').then( m => m.ProfileInfoStep4PageModule)
  },
  {
    path: 'capture-photo',
    loadChildren: () => import('./capture-photo/capture-photo.module').then( m => m.CapturePhotoPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'payment-info',
    loadChildren: () => import('./payment-info/payment-info.module').then( m => m.PaymentInfoPageModule)
  },
  {
    path: 'payment-edit',
    loadChildren: () => import('./payment-edit/payment-edit.module').then( m => m.PaymentEditPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'update-license',
    loadChildren: () => import('./update-license/update-license.module').then( m => m.UpdateLicensePageModule)
  },
  {
    path: 'seeker-info',
    loadChildren: () => import('./profile-information/seeker-info/seeker-info.module').then( m => m.SeekerInfoPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'posted-jobs',
    loadChildren: () => import('./posted-jobs/posted-jobs.module').then( m => m.PostedJobsPageModule)
  },
  {
    path: 'my-seeker-reviews',
    loadChildren: () => import('./my-seeker-reviews/my-seeker-reviews.module').then( m => m.MySeekerReviewsPageModule)
  },
  {
    path: 'my-notes',
    loadChildren: () => import('./my-notes/my-notes.module').then( m => m.MyNotesPageModule)
  },
  {
    path: 'new-notes',
    loadChildren: () => import('./new-notes/new-notes.module').then( m => m.NewNotesPageModule)
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./confirmation/confirmation.module').then( m => m.ConfirmationPageModule)
  },
  {
    path: 'single-job',
    loadChildren: () => import('./single-job/single-job.module').then( m => m.SingleJobPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'single-active-job',
    loadChildren: () => import('./single-active-job/single-active-job.module').then( m => m.SingleActiveJobPageModule)
  },
  {
    path: 'single-complete-job-page',
    loadChildren: () => import('./single-complete-job-page/single-complete-job-page.module').then( m => m.SingleCompleteJobPagePageModule)
  },
  {
    path: 'my-posted-job',
    loadChildren: () => import('./my-posted-job/my-posted-job.module').then( m => m.MyPostedJobPageModule)
  },
  {
    path: 'view-rating',
    loadChildren: () => import('./view-rating/view-rating.module').then( m => m.ViewRatingPageModule)
  },
  {
    path: 'list-myjob',
    loadChildren: () => import('./list-myjob/list-myjob.module').then( m => m.ListMyjobPageModule)
  },
  {
    path: 'choosetype',
    loadChildren: () => import('./choosetype/choosetype.module').then( m => m.ChoosetypePageModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('./billing/billing.module').then( m => m.BillingPageModule)
  },
  {
    path: 'billingedit',
    loadChildren: () => import('./billingedit/billingedit.module').then( m => m.BillingeditPageModule)
  },
  {
    path: 'cardinfo',
    loadChildren: () => import('./cardinfo/cardinfo.module').then( m => m.CardinfoPageModule)
  },
  {
    path: 'managebankcards',
    loadChildren: () => import('./managebankcards/managebankcards.module').then( m => m.ManagebankcardsPageModule)
  },
  {
    path: 'make-payment',
    loadChildren: () => import('./make-payment/make-payment.module').then( m => m.MakePaymentPageModule)
  },
  {
    path: 'my-transaction',
    loadChildren: () => import('./my-transaction/my-transaction.module').then( m => m.MyTransactionPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'billingedit/:type/:card_id/:card_last4/:card_brand/:card_exp_month/:card_exp_year/:card_name/edit/:default',
    loadChildren: () => import('./billingedit/billingedit.module').then( m => m.BillingeditPageModule)
  },
  {
    path: 'billing/:type',
    loadChildren: () => import('./billing/billing.module').then( m => m.BillingPageModule)
  },
  {
    path: 'billing/:type/:page',
    loadChildren: () => import('./billing/billing.module').then( m => m.BillingPageModule)
  },
  {
    path: 'set-hours-edit',
    loadChildren: () => import('./set-hours-edit/set-hours-edit.module').then( m => m.SetHoursEditPageModule)
  },
  {
    path: 'payment-information',
    loadChildren: () => import('./payment-information/payment-information.module').then( m => m.PaymentInformationPageModule)
  },
  {
    path: 'custom-wallet',
    loadChildren: () => import('./custom-wallet/custom-wallet.module').then( m => m.CustomWalletPageModule)
  },
  {
    path: 'custom-wallet',
    loadChildren: () => import('./custom-wallet/custom-wallet.module').then( m => m.CustomWalletPageModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'help-page',
    loadChildren: () => import('./help-page/help-page.module').then( m => m.HelpPagePageModule)
  },
  {
    path: 'mail-send',
    loadChildren: () => import('./mail-send/mail-send.module').then( m => m.MailSendPageModule)
  },
  {
    path: 'special-code',
    loadChildren: () => import('./special-code/special-code.module').then( m => m.SpecialCodePageModule)
  },
  {
    path: 'verify-password',
    loadChildren: () => import('./verify-password/verify-password.module').then( m => m.VerifyPasswordPageModule)
  },
  {
    path: 'correct',
    loadChildren: () => import('./correct/correct.module').then( m => m.CorrectPageModule)
  },
  {
    path: 'incorrect',
    loadChildren: () => import('./incorrect/incorrect.module').then( m => m.IncorrectPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'review-hours/:job_id',
    loadChildren: () => import('./review-hours/review-hours.module').then( m => m.ReviewHoursPageModule)
  },
  {
    path: 'mycalendar',
    loadChildren: () => import('./mycalendar/mycalendar.module').then( m => m.MycalendarPageModule)
  },
  {
    path: 'myemployee',
    loadChildren: () => import('./myemployee/myemployee.module').then( m => m.MyemployeePageModule)
  },
  {
    path: 'jobrequest',
    loadChildren: () => import('./jobrequest/jobrequest.module').then( m => m.JobrequestPageModule)
  },
  {
    path: 'editcompany',
    loadChildren: () => import('./editcompany/editcompany.module').then( m => m.EditcompanyPageModule)
  },
  {
    path: 'job-listing',
    loadChildren: () => import('./job-listing/job-listing.module').then( m => m.JobListingPageModule)
  },
  {
    path: 'schedule',
    loadChildren: () => import('./schedule/schedule.module').then( m => m.SchedulePageModule)
  },
  {
    path: 'availability',
    loadChildren: () => import('./availability/availability.module').then( m => m.AvailabilityPageModule)
  },
  {
    path: 'removejob',
    loadChildren: () => import('./removejob/removejob.module').then( m => m.RemovejobPageModule)
  },
  {
    path: 'transportation',
    loadChildren: () => import('./transportation/transportation.module').then( m => m.TransportationPageModule)
  },
  {
    path: 'featured-job',
    loadChildren: () => import('./featured-job/featured-job.module').then( m => m.FeaturedJobPageModule)
  },
  {
    path: 'save-job',
    loadChildren: () => import('./save-job/save-job.module').then( m => m.SaveJobPageModule)
  },
  {
    path: 'resume',
    loadChildren: () => import('./resume/resume.module').then( m => m.ResumePageModule)
  },
  {
    path: 'references',
    loadChildren: () => import('./references/references.module').then( m => m.ReferencesPageModule)
  },
  {
    path: 'new-references',
    loadChildren: () => import('./new-references/new-references.module').then( m => m.NewReferencesPageModule)
  },
  {
    path: 'additional-qualifications',
    loadChildren: () => import('./additional-qualifications/additional-qualifications.module').then( m => m.AdditionalQualificationsPageModule)
  },
  {
    path: 'resume-uploaded',
    loadChildren: () => import('./resume-uploaded/resume-uploaded.module').then( m => m.ResumeUploadedPageModule)
  },
  {
    path: 'update-references',
    loadChildren: () => import('./update-references/update-references.module').then( m => m.UpdateReferencesPageModule)
  },
  {
    path: 'work-history',
    loadChildren: () => import('./work-history/work-history.module').then( m => m.WorkHistoryPageModule)
  },
  {
    path: 'work-history-info',
    loadChildren: () => import('./work-history-info/work-history-info.module').then( m => m.WorkHistoryInfoPageModule)
  },
  {
    path: 'work-history-info/:work_history_id',
    loadChildren: () => import('./work-history-info/work-history-info.module').then( m => m.WorkHistoryInfoPageModule)
  },
  {
    path: 'existing-user',
    loadChildren: () => import('./existing-user/existing-user.module').then( m => m.ExistingUserPageModule)
  },
  {
    path: 'tag-employee',
    loadChildren: () => import('./tag-employee/tag-employee.module').then( m => m.TagEmployeePageModule)
  },
  {
    path: 'list-not-approved-job',
    loadChildren: () => import('./list-not-approved-job/list-not-approved-job.module').then( m => m.ListNotApprovedJobPageModule)
  },
  {
    path: 'available-jobs',
    loadChildren: () => import('./available-jobs/available-jobs.module').then( m => m.AvailableJobsPageModule)
  },
  {
    path: 'my-employees',
    loadChildren: () => import('./my-employees/my-employees.module').then( m => m.MyEmployeesPageModule)
  },
  {
    path: 'clock-in-clock-out/:job_id',
    loadChildren: () => import('./clock-in-clock-out/clock-in-clock-out.module').then( m => m.ClockInClockOutPageModule)
  },
  {
    path: 'clock-in-clock-out/:job_id/:id',
    loadChildren: () => import('./clock-in-clock-out/clock-in-clock-out.module').then( m => m.ClockInClockOutPageModule)
  },
  {
    path: 'review-hours2/:job_id/:uid',
    loadChildren: () => import('./review-hours2/review-hours2.module').then( m => m.ReviewHours2PageModule)
  },
  {
    path: 'new-event',
    loadChildren: () => import('./new-event/new-event.module').then( m => m.NewEventPageModule)
  },
  {
    path: 'apply-new-user',
    loadChildren: () => import('./apply-new-user/apply-new-user.module').then( m => m.ApplyNewUserPageModule)
  },
  {
    path: 'clock-in',
    loadChildren: () => import('./clock-in/clock-in.module').then( m => m.ClockInPageModule)
  },
  {
    path: 'basic-info',
    loadChildren: () => import('./basic-info/basic-info.module').then( m => m.BasicInfoPageModule)
  },
  {
    path: 'upload-edu-info',
    loadChildren: () => import('./upload-edu-info/upload-edu-info.module').then( m => m.UploadEduInfoPageModule)
  },

  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'work-histroy-detail',
    loadChildren: () => import('./work-histroy-detail/work-histroy-detail.module').then( m => m.WorkHistroyDetailPageModule)
  },
  {
    path: 'send-job-request',
    loadChildren: () => import('./send-job-request/send-job-request.module').then( m => m.SendJobRequestPageModule)
  },
  {
    path: 'availability-details',
    loadChildren: () => import('./availability-details/availability-details.module').then( m => m.AvailabilityDetailsPageModule)
  },
  {
    path: 'reference-details',
    loadChildren: () => import('./reference-details/reference-details.module').then( m => m.ReferenceDetailsPageModule)
  },
  {
    path: 'specialty-modal',
    loadChildren: () => import('./modal/specialty-modal/specialty-modal.module').then( m => m.SpecialtyModalPageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./delete-account/delete-account.module').then( m => m.DeleteAccountPageModule)
  },
  {
    path: 'payout-to-employee',
    loadChildren: () => import('./payout-to-employee/payout-to-employee.module').then( m => m.PayoutToEmployeePageModule)
  },
  {
    path: 'employee-hours',
    loadChildren: () => import('./employee-hours/employee-hours.module').then( m => m.EmployeeHoursPageModule)
  },
  {
    path: 'walletrequest',
    loadChildren: () => import('./walletrequest/walletrequest.module').then( m => m.WalletrequestPageModule)
  },
  {
    path: 'payment-history',
    loadChildren: () => import('./payment-history/payment-history.module').then( m => m.PaymentHistoryPageModule)
  },
  {
    path: 'payment-subscription',
    loadChildren: () => import('./payment-subscription/payment-subscription.module').then( m => m.PaymentSubscriptionPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
